import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import { StaticImage } from 'gatsby-plugin-image'
import faq from '/src/data/faq'
import './styles.scss'

const Accordion = loadable(() => import('/src/components/Accordion/Accordion'))
const Callout = loadable(() => import('/src/components/Callout'))
const CalloutWithImage = loadable(() =>
    import('/src/components/CalloutWithImage')
)
const Card = loadable(() => import('/src/components/Card'))
const Disclaimer = loadable(() => import('/src/components/Disclaimer'))
const Footer = loadable(() => import('/src/components/Footer'))
const ImageList = loadable(() => import('/src/components/ImageList'))
const InnerSection = loadable(() => import('/src/components/InnerSection'))
const LeadGenJourney = loadable(() => import('/src/forms/LeadGenJourney'))
const Section = loadable(() => import('/src/components/Section'))
const Seo = loadable(() => import('/src/components/Seo'))
const Steps = loadable(() => import('/src/components/Steps'))

const FormPage = ({ postInfo, post, leadSource, successPage }) => {
    return (
        <>
            <Seo postInfo={postInfo} post={post} />

            <main>
                <Section variant="alt" className="c-page-body">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <Card>
                                <InnerSection>
                                    <h1 className="c-form-title">
                                        {parse(post.title)}
                                    </h1>
                                </InnerSection>

                                <InnerSection variant="alt">
                                    <ImageList>
                                        <StaticImage
                                            src="../../images/insurer-logos-colour/aviva.png"
                                            height={40}
                                            alt="Aviva logo"
                                            className="c-image-list__item c-image-list__item--hide"
                                        />
                                        <StaticImage
                                            src="../../images/insurer-logos-colour/vitality.png"
                                            height={35}
                                            alt="Vitality logo"
                                            className="c-image-list__item c-image-list__item--hide"
                                        />
                                        <StaticImage
                                            src="../../images/insurer-logos-colour/lv.png"
                                            height={35}
                                            alt="LV logo"
                                            className="c-image-list__item"
                                        />
                                        <StaticImage
                                            src="../../images/insurer-logos-colour/legal-general.png"
                                            height={40}
                                            alt="Legal and General logo"
                                            className="c-image-list__item"
                                        />
                                        <StaticImage
                                            src="../../images/insurer-logos-colour/scottish-widows.png"
                                            height={35}
                                            alt="Scotish Widows logo"
                                            className="c-image-list__item c-image-list__item--hide"
                                        />
                                    </ImageList>
                                </InnerSection>

                                <LeadGenJourney
                                    leadSource={leadSource}
                                    successPage={successPage}
                                />
                            </Card>

                            <Disclaimer />
                        </div>
                    </div>
                </Section>

                <Steps />

                <Callout>
                    <h2>
                        Want to know more about <span>Life Insurance</span>?
                    </h2>
                    <p>
                        Check out some of our Frequently Asked Questions below
                    </p>
                </Callout>

                <Section variant="alt">
                    <Accordion data={faq} />

                    <CalloutWithImage />
                </Section>
            </main>

            <Footer />
        </>
    )
}

FormPage.propTypes = {
    /**
     * Post info object
     */
    postInfo: PropTypes.object.isRequired,
    /**
     * Post object
     */
    post: PropTypes.object.isRequired,
    /**
     * Optional different lead source
     */
    leadSource: PropTypes.string,
    /**
     * Optional success page URL
     */
    successPage: PropTypes.string
}

export default FormPage
